import { useLazyQuery } from "@apollo/client";
import { getHashParams, useOnErrorGraphql } from "@newrai/ant-design";
import { clientNeoAuth } from "@newrai/api-clients";
import * as Sentry from "@sentry/browser";
import i18n from "config/i18n/i18n";
import settings from "config/settings";
import dayjs from "dayjs";
import useUserDataStore from "hooks/useUserDataStore";
import Cookies from "js-cookie";
import { IS_AUTH_QUERY } from "queries/neoauth";
import React, { useEffect, useRef } from "react";
import { hotjar } from "react-hotjar";
import { useLocation, useNavigate } from "react-router-dom";
import Base from "views/Base";

const ACCESS_PERMISSION = "neossi.can-access";

export default function PrivateRoute(): React.ReactElement {
  const { onErrorGraphql } = useOnErrorGraphql();
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, setUserData } = useUserDataStore();
  const relativeUrlPath = useRef("");
  const relativePathName = `${location.pathname}${location.search}`;

  const [getUserData] = useLazyQuery(IS_AUTH_QUERY, {
    client: clientNeoAuth,
    onError: onErrorGraphql,
    onCompleted: ({ getUserByToken }) => {
      if (getUserByToken) {
        const canAccess = getUserByToken.permissions
          ? getUserByToken.permissions.findIndex(
              (permission) => permission === ACCESS_PERMISSION,
            ) > -1
          : false;

        if (!canAccess) {
          navigate("/no-access");
        } else {
          setUserData(getUserByToken);
          Sentry.setUser({
            email: getUserByToken.email,
            id: getUserByToken.id,
          });
          i18n.changeLanguage(getUserByToken.language.toLowerCase());
          navigate(relativeUrlPath.current, { replace: true });
        }
        if (getUserByToken.isNewraiUser) {
          hotjar.identify(getUserByToken.id, {
            first_name: getUserByToken.firstName,
            last_name: getUserByToken.lastName,
            email: getUserByToken.email,
          });
          hotjar.event("newrai_user");
        }
      } else {
        window.location.href = settings.fullLoginUrl;
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleLoginUseEffect = async () => {
    relativeUrlPath.current = relativePathName;

    if (location.hash) {
      const expireSeconds = getHashParams(location, "expires_in");
      const expireDate = dayjs().add(Number(expireSeconds) * 1000, "ms");
      const accessTokenValue = getHashParams(location, "access_token");

      Cookies.set("access_token", accessTokenValue, {
        path: "/",
        expires: expireDate.toDate(),
      });
      Cookies.set("expireDate", expireDate.toISOString(), {
        path: "/",
        expires: expireDate.toDate(),
      });
    }

    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const relativeUrlPathParam = urlParams.get("relative_url_path");
      if (relativeUrlPathParam) {
        relativeUrlPath.current = relativeUrlPathParam;
      }
    }

    const cookieAccessToken = Cookies.get("access_token");
    if (cookieAccessToken && !userData) {
      await getUserData();
    } else {
      window.location.href = settings.fullLoginUrl;
      return;
    }
  };

  useEffect(() => {
    handleLoginUseEffect();
  }, []);

  return <>{userData && <Base />}</>;
}
