import create, { State, StateCreator } from "zustand";
import { devtools } from "zustand/middleware";
import { Draft, produce } from "immer";
import { pipe } from "ramda";

const immer =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>,
  ): StateCreator<T> =>
  (set, get, api): any =>
    config(fn => set(produce<T>(fn)), get, api);

export let createStore = pipe(immer, create);

if (process.env.NODE_ENV === "development") {
  createStore = pipe(immer, devtools, create);
}

export const setStateHelper = (
  argName: string,
  argValue: SetStateArgs<any>,
  set: (fn: (draft: Record<string, any>) => void) => void,
): void => {
  set(state => ({
    ...state,
    [argName]: argValue,
  }));
};
