import { gql } from "@apollo/client";

export const IS_AUTH_QUERY = gql`
  query getUser {
    isAuthenticated {
      status
    }
    getUserByToken {
      accountingFirms
      companies
      isActive
      userCategory {
        id
        name
        slug
      }
      isSuperuser
      isNewraiUser
      id
      firstName
      lastName
      email
      username
      permissions
      language
      groups {
        slug
      }
    }
  }
`;

export const GET_OPS = gql`
  query listOPS(
    $search: String
    $companyId: String
    $offset: Int
    $isNewraiUser: Boolean
  ) {
    listUsersPaginated(
      searchContains: $search
      companies_Contains: $companyId
      permission_In: ["neossi-api.can-validate-book-entries"]
      isNewraiUser: $isNewraiUser
      isActive: true
    ) {
      results(limit: 10, offset: $offset) {
        id
        firstName
        lastName
        email
      }
      totalCount
    }
  }
`;

export const LIST_APPS_QUERY = gql`
  query listApplications($isHidden: Boolean) {
    listApplications(filter: { isHidden: $isHidden }) {
      id
      name
      url
      slug
      logo
      order
      inDevelopment
      pathOption
      category {
        slug
      }
    }
    listApplicationCategories {
      slug
      name
      order
    }
  }
`;
