import { HeaderWithOptions } from "@newrai/ant-design";
import {
  clientNeoActivite,
  clientNeoActiviteWS,
  clientNeoAuth,
  clientNeoDocs,
} from "@newrai/api-clients";
import { Layout } from "antd";
import axios from "axios";
import { langsCode, langsLabel } from "config/i18n/i18n";
import { routes } from "config/routes";
import Settings, { APP_URL } from "config/settings";
import useUserDataStore from "hooks/useUserDataStore";
import Cookies from "js-cookie";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import logo from "resources/images/logo-full.png";
import useBulkStore from "views/Bulk/hooks/useBulkStore";
import AppsMenu from "../AppsMenu";
import MenuHeader from "./MenuHeader";

function ApplicationWrapper() {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  const userData = useUserDataStore(state => state.userData);
  const resetStore = useUserDataStore(state => state.resetStore);
  const { firstName, lastName } = userData;
  const companySelected = useBulkStore(state => state.company);
  const isNewrai = userData?.isNewraiUser;

  const { companyId }: Params = useParams();

  const handleLogout = async () => {
    const resetStores = (): void => {
      clientNeoAuth.resetStore();
      resetStore();
    };

    const resetCookies = (): void => {
      Cookies.remove("access_token");
      Cookies.remove("expireDate");
    };

    await axios(Settings.revokeToken, {
      method: "post",
      data: `token=${Cookies.get("access_token")}&client_id=${Settings.clientId}`,
    }).then(() => {
      resetStores();
      resetCookies();
      routes.navigate("/", { replace: true });
    });
  };

  const changeLanguage = (lang: string): void => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const languageOptions =
    process.env.NODE_ENV === "development"
      ? {
          changeLanguage: (e): void => changeLanguage(e),
          languageCodes: langsCode,
          languageLabels: langsLabel.map(lang => t(lang)),
          selectedLanguage: language,
        }
      : undefined;

  const canUpdateCompany =
    userData?.permissions &&
    userData.permissions.find((item: string) => item === "central-api.can-update-companies");

  return (
    <Layout>
      <AppsMenu />
      <HeaderWithOptions
        avatarOptions={{
          firstName: firstName,
          lastName: lastName,
          onLogout: handleLogout,
        }}
        {...((companyId || companySelected?.id) && canUpdateCompany
          ? {
              goToDashboardOptions: {
                url: `${APP_URL.dashboard}companies/${companySelected?.id || companyId}/general/general-info`,
              },
            }
          : {})}
        neoactivite={{
          neoActiviteClientHttp: clientNeoActivite,
          neoActiviteClientWS: clientNeoActiviteWS,
          neoActiviteUrl: APP_URL.neoActiviteUrl,
        }}
        languageOptions={languageOptions}
        logoOptions={{
          src: logo,
          alt: "Neossi",
        }}
        freshDeskWidget={{
          autofill: {
            requester: userData?.email,
            name: userData?.firstName,
            subject: companySelected
              ? `${companySelected?.accountingFirm?.name} - ${companySelected?.internalReference}: ${companySelected?.name}
            `
              : "",
          },
          formTitle: t("Client support"),
          isNewrai: isNewrai,
        }}
        centeredItems={<MenuHeader />}
        showModalATC={{
          neoDocsClientHttp: clientNeoDocs,
          neoActiviteClientWS: clientNeoActiviteWS,
          neoscanExpertUrl: APP_URL.neoScanExpertUrl,
        }}
        isATC={false}
        // isATC={Boolean(canRegister) && !userData?.isSuperuser}
      />
    </Layout>
  );
}

export default React.memo(ApplicationWrapper, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
