import { Layout } from "antd";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ApplicationWrapper from "./AppWrapper";
import LoadingPage from "./LoadingPage";

export default function Base(): React.ReactElement {
  return (
    <Layout>
      <ApplicationWrapper />
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
}
