import { Button, Card, Col, Row } from "antd";
import { routes } from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";

function Page404(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Row justify="center">
      <Col>
        <Card>
          <div className="text-center">
            <h2>{t("Error 404")}</h2>
            <h2>{t("Oops Page Not Found")}</h2>
            <p>{t("The page you are looking for does not exist or has been moved.")}</p>
            <Button onClick={() => routes.navigate("/")}>{t("Go to Home")}</Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default Page404;
