import { createStore, setStateHelper } from "config/store";

const useUserDataStore = createStore((set: (fn: (draft: Record<string, any>) => void) => void) => ({
  userData: undefined,
  resetStore: () => {
    set(() => ({
      userData: undefined,
    }));
  },
  setUserData: (userData: GetUserByToken) => {
    setStateHelper("userData", userData, set);
  },
}));

export default useUserDataStore;
