import { Button, Space } from "antd";
import { routes } from "config/routes";
import { APP_URL } from "config/settings";
import usePermissions from "hooks/usePermissions";
import useUserDataStore from "hooks/useUserDataStore";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function MenuHeader(): React.ReactElement {
  const { t } = useTranslation();
  const location = useLocation();
  const { canViewVatManagement, canViewManagment, canViewAdministrator } = usePermissions();
  const { pathname } = location;
  const userData = useUserDataStore(state => state.userData);
  const { isNewraiUser } = userData;

  const buttonLabel = canViewAdministrator ? t("Administration") : t("Management");

  return (
    <Space>
      <Button type={pathname === "/" ? "default" : "link"} onClick={() => routes.navigate("/")}>
        {t("Home")}
      </Button>
      {canViewVatManagement && isNewraiUser && (
        <Button
          type={pathname.startsWith("/accounting-vat") ? "default" : "link"}
          onClick={() => routes.navigate("/accounting-vat")}
        >
          {t("Documents without VAT")}
        </Button>
      )}
      {(canViewAdministrator || canViewManagment) && (
        <Button type="link" target="_blank" href={`${APP_URL.neoAffectation}neossi/not-validated`}>
          {buttonLabel}
        </Button>
      )}
    </Space>
  );
}
